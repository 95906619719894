<template>
  <div>
    <div
      class="comment py-2 d-flex justify-content-start align-items-center mb-2"
    >
      <div class="avatar">
        <img
          v-if="comments.comment.user.avatar"
          :src="comments.comment.user.avatar"
          alt="user"
          class="rounded-circle d-block border border-3"
          width="90"
        />
        <img
          v-else
          src="@/assets/images/avatar.png"
          alt="user"
          class="rounded-circle d-block border border-3"
          width="90"
        />
      </div>
      <div class="comment me-3">
        <div class="d-flex align-items-center justify-content-between">
          <h1 class="h5 mb-0 fw-bold edc-color-primary">
            {{ comments.comment.user.name }}
          </h1>
          <span class="text-muted d-flex align-items-center fs-6">
            <i class="far fa-clock mx-2"></i>
            {{ comments.comment.comment_date }}
          </span>
        </div>
        <div>
          {{ comments.comment.comment_content }}
        </div>
      </div>
    </div>
    <ul v-if="comments.child" class="list-group list-group-flush">
      <li
        v-for="(childcomment, index) in comments.child"
        :key="index"
        class="list-group-item"
      >
        <child-comment :comments="childcomment"></child-comment>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "child-comment",
  props: {
    comments: {
      user: {
        avatar: "",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>