<template>
  <div>
    <form @submit.prevent="doSendComment()">
      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>
      <div v-if="Object.keys(user).length <= 0">
        <div class="form-label fs-7">{{ $t("label.comment_name") }}</div>
        <div class="input-group mb-3">
          <input
            type="text"
            v-model="form.name"
            :class="{ 'is-invalid': isDisplayError('name') }"
            class="form-control rounded-pill"
            :placeholder="$t('label.comment_name')"
            aria-label="Name"
            aria-describedby="name"
          />
        </div>
        <div class="form-label fs-7">{{ $t("label.comment_email") }}</div>
        <div class="input-group mb-3">
          <input
            type="email"
            :class="{ 'is-invalid': isDisplayError('email') }"
            v-model="form.email"
            class="form-control rounded-pill"
            :placeholder="$t('label.comment_email')"
            aria-label="Email"
            aria-describedby="email"
          />
        </div>
      </div>
      <div v-else class="d-flex align-items-center mb-2">
        <span>
          <img
            width="80"
            class="rounded-circle d-block border border-3"
            :src="user.user_avatar"
            :alt="user.user_nicename"
          />
        </span>
        <span class="fw-bold mx-2">
          {{ user.user_nicename }}
        </span>
      </div>
      <div class="form-label fs-7">{{ $t("label.comment_text") }}</div>
      <div class="input-group mb-3">
        <textarea
          class="form-control rounded-30"
          id="comment"
          :class="{ 'is-invalid': isDisplayError('comment') }"
          v-model="form.comment"
          :placeholder="$t('label.comment_text')"
          rows="3"
        ></textarea>
      </div>
      <div class="d-grid gap-1 col-3 mx-auto">
        <button
          class="
            btn
            edc-btn-outline-secoundry edc-color-secondary
            rounded-30
            py-3
          "
          type="submit"
        >
          {{ $t("btn.add_comment") }}
          <i class="far fa-comment-dots"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import stroe from "../store/index";
export default {
  name: "CommentForm",
  props: {
    post_id: "",
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        comment: "",
        user_id: "",
      },
      input_errors: [],
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("comment", ["sendComment", "getComments"]),
    doSendComment() {
      this.errorMessage = "";
      let formData = new FormData();
      formData.append("post_id", this.post_id);
      formData.append("email", this.form.email);
      formData.append("name", this.form.name);
      formData.append("comment", this.form.comment);
      formData.append("user_id", this.form.user_id);
      this.sendComment(formData)
        .then((response) => {
          if (response.status === 200) {
            this.$swal({
              icon: "success",
              title: response.data.message,
            });
            this.form = {};
            this.input_errors = [];
            stroe.commit("comment/PUSH_ONE_COMMENT", {
              comment: response.data.comment,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.input_errors = error.response.data.data.params;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    isDisplayError(field) {
      return this.input_errors.includes(field);
    },
  },
  watch: {
    user: {
      deep: true,

      // We have to move our method to a handler field
      handler(newVal) {
        this.form.user_id = newVal.ID;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>