<template>
  <div>
    <div
      class="
        d-flex
        justify-content-between
        align-items-center
        my-2
        col-8
        mx-auto
        py-1
        border-bottom
      "
    >
      <div
        :class="`text-white bg-${item.type}`"
        class="icon-notify ms-2 py-2 px-3 rounded-10"
      >
        <i
          class="fas"
          :class="{
            'fa-exclamation-circle': item.type === 'info',
            'fa-check-circle': item.type === 'success',
            'fa-exclamation-triangle': item.type === 'waring',
            'fa-times-circle': item.type === 'danger',
          }"
        ></i>
      </div>
      <div class="flex-fill me-2">
        <a :href="item.data.url" target="_blank" v-html="item.data.message"></a>
      </div>
      <small>{{ item.since }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "notification",
  props: {
    item: {},
  },
};
</script>

<style lang="scss" scoped>
</style>