<template>
  <!--report-post-item-->
  <div class="mb-4" :class="gridClass">
    <div
      class="reports-posts-item rounded-50 p-2 pb-3 edc-content-round"
      :class="display_list"
    >
      <div class="reports-posts-item-right">
        <div class="reports-item-thumble">
          <img
            :src="singlePost.image.medium_large"
            class="reports-thumble-img rounded-50 w-100 img-fluid"
            :alt="singlePost.title"
            :title="singlePost.title"
            srcset=""
          />
        </div>
      </div>
      <div class="reports-posts-item-left flex-fill">
        <div
          class="
            h4
            py-2
            reports-item-title
            border-1
            text-truncate
            border-bottom
          "
        >
          {{ singlePost.title }}
        </div>
        <div
          class="
            p-2
            reports-item-title
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <div
            class="
              details
              fs-7
              d-flex
              justify-content-between
              align-items-center
              text-muted
            "
          >
            <span class="d-flex align-items-center fs-6">
              <i class="far fa-file-alt ms-1"></i>
              <span>{{ $t("label.page_count") }}</span>
            </span>
          </div>
          <div class="tags">
            <span
              class="btn rounded-pill fs-7 me-1 edc-btn-primary text-white"
              >{{ singlePost.pages }}</span
            >
          </div>
        </div>
        <div class="reports-item-body p-2 h6 text-muted lh-base">
          {{ singlePost.content }}
        </div>
        <div class="reports-item-button d-grid">
          <button
            class="
              btn
              rounded-pill
              py-3
              px-4
              mx-2
              d-block
              edc-btn-outline-secoundry
              my-2
            "
            @click="downloadFile(singlePost.file)"
          >
            {{ $t("btn.download") }}
            <i class="fas fa-download me-1"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--//report-post-item-->
</template>

<script>
export default {
  name: "Reports",
  props: {
    report: {},
  },
  data() {
    return {
      singlePost: {},
      gridClass: "col-lg-4 col-md-6",
      display_list: "",
    };
  },
  methods: {
    downloadFile: function (file) {
      if (file) {
        window.open(file, "_blank");
      }
      // console.log("file ", file);
      return false;
    },
  },
  created() {
    this.singlePost = this.report;
  },
  mounted() {
    //do event update Header ShopCart
    if (localStorage.getItem("reports-display") === "row") {
      this.gridClass = "col-lg-12 col-md-12";
      this.display_list = "display-list d-flex gap-4";
    }
    if (localStorage.getItem("reports-display") === "box") {
      this.gridClass = "col-lg-4 col-md-6";
      this.display_list = "";
    }
    this.$root.$on("updateReportsView", (data, grid) => {
      // here you need to use the arrow function
      this.gridClass = data;
      this.display_list = grid;
    });
  },
};
</script>

<style scoped>
</style>