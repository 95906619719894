<template>
  <div>
    <div>
      <loading :isShowLoading="isShowLoading"></loading>
    </div>

    <ul class="post-comment-list list-group list-group-flush ps-2 pe-1">
      <li
        class="list-group-item px-1"
        v-for="(comments, index) in commentslist"
        :key="index"
      >
        <div
          class="
            comment
            py-2
            d-flex
            justify-content-start
            align-items-center
            mb-2
          "
        >
          <div class="avatar">
            <img
              v-if="comments.comment.user.avatar"
              :src="comments.comment.user.avatar"
              alt="user"
              class="rounded-circle d-block border border-3"
              width="90"
            />
            <img
              v-else
              src="@/assets/images/avatar.png"
              alt="user"
              class="rounded-circle d-block border border-3"
              width="90"
            />
          </div>
          <div class="comment me-3">
            <div class="d-flex align-items-center justify-content-between">
              <h1 class="h5 mb-0 fw-bold edc-color-primary">
                {{ comments.comment.user.name }}
              </h1>
              <span class="text-muted d-flex align-items-center fs-6">
                <i class="far fa-clock mx-2"></i>
                {{ comments.comment.comment_date }}
              </span>
            </div>

            <div>
              {{ comments.comment.comment_content }}
            </div>
          </div>
        </div>
        <ul v-if="comments.child" class="list-group list-group-flush">
          <li
            v-for="(childcomment, index) in comments.child"
            :key="index"
            class="list-group-item"
          >
            <child-comment :comments="childcomment"></child-comment>
          </li>
        </ul>
      </li>
    </ul>
    <!--loadmore-->
    <div
      v-if="isShowLoadMoreButton"
      class="my-donations-loadmore my-3 py-3 text-center fs-5 fw-bold"
    >
      <a href="#" @click.prevent="loadMore()" class="edc-color-primary"
        ><span class="ms-4 d-inline">{{ $t("btn.load_more") }}</span>
        <i class="fas fa-chevron-down"></i
      ></a>
    </div>
    <!--//loadmore--->
  </div>
</template>

<script>
import ChildComment from "./ChildComment";
import { mapGetters, mapActions } from "vuex";
import Loading from "./Loading.vue";
export default {
  name: "comments",
  props: {
    post_id: "",
  },
  data() {
    return {
      limit: 5,
      offset: 0,
    };
  },
  computed: {
    ...mapGetters("comment", [
      "commentslist",
      "isShowLoadMoreButton",
      "isShowLoading",
    ]),
  },
  methods: {
    ...mapActions("comment", ["getComments", "reset_comment"]),
    loadMore() {
      this.offset++;
      this.getComments({
        post_id: this.post_id,
        limit: this.limit,
        offset: this.offset,
      });
    },
  },
  created() {
    this.reset_comment([]);

    this.getComments({
      post_id: this.post_id,
      limit: this.limit,
    });
  },
  components: {
    ChildComment,
    Loading,
  },
};
</script>

<style lang="scss" scoped>
</style>