<template>
  <!--new-post-item-->
  <div class="mb-4" :class="gridClass">
    <div
      class="news-posts-item rounded-50 p-2 pb-3 edc-content-round"
      :class="display_list"
    >
      <div class="news-posts-item-right">
        <div class="news-item-thumble">
          <router-link :to="{ path: '/news/show/' + singlePost.post.slug }">
            <img
              :src="singlePost.post.post_image.medium_large"
              class="news-thumble-img rounded-50 w-100 img-fluid"
              :alt="singlePost.post.post_title"
              :title="singlePost.post.post_title"
              srcset=""
            />
          </router-link>
        </div>
      </div>
      <div class="news-posts-item-left">
        <div class="news-item-title border-1 border-bottom">
          <router-link
            :to="{ path: '/news/show/' + singlePost.post.slug }"
            class="h4 color-black py-3 d-block"
          >
            {{ singlePost.post.post_title }}
          </router-link>
        </div>
        <div
          class="
            py-2
            news-item-title
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <div
            class="
              details
              fs-7
              d-flex
              justify-content-between
              align-items-center
              text-muted
            "
          >
            <span class="d-flex align-items-center">
              <i class="far fa-comment-alt fs-4 ms-1"></i>
              <span
                >{{ singlePost.post.comment_count }}
                {{ $t("label.comment") }}</span
              >
            </span>
            <span class="d-flex align-items-center">
              <i class="far fa-clock fs-4 mx-1"></i>
              <span>{{ singlePost.post.post_date }}</span>
            </span>
          </div>
          <div class="tags">
            <router-link
              :to="{ path: '/news/category/' + cat.slug }"
              v-for="cat in singlePost.categories.slice(0, 2)"
              :key="cat.cat_ID"
              class="btn rounded-pill fs-7 me-1 edc-btn-primary text-white"
              >{{ cat.cat_name }}</router-link
            >
          </div>
        </div>
        <div class="news-item-body p-2 h5 lh-base">
          {{ singlePost.post.post_excerpt }}
        </div>
        <div class="news-item-button">
          <router-link
            :to="{ path: '/news/show/' + singlePost.post.slug }"
            class="
              btn
              rounded-pill
              py-3
              px-4
              mx-5
              d-block
              edc-btn-outline-secoundry
              my-2
            "
          >
            {{ $t("btn.read_more") }}
            <i class="fas fa-chevron-left me-1"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!--//new-post-item-->
</template>

<script>
export default {
  name: "news",
  props: {
    post: {},
  },
  data() {
    return {
      singlePost: {},
      gridClass: "col-lg-4 col-md-6",
      display_list: "",
    };
  },
  created() {
    this.singlePost = this.post;
  },
  mounted() {
    //do event update Header ShopCart
    if (localStorage.getItem("news-display") === "row") {
      this.gridClass = "col-lg-12 col-md-12";
      this.display_list = "display-list d-flex gap-4";
    }
    if (localStorage.getItem("news-display") === "box") {
      this.gridClass = "col-lg-4 col-md-6";
      this.display_list = "";
    }
    this.$root.$on("updateNewsView", (data, grid) => {
      // here you need to use the arrow function
      this.gridClass = data;
      this.display_list = grid;
    });
  },
};
</script>

<style lang="scss" scoped>
</style>