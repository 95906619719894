<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <div class="edc-news-box">
          <loading :isShowLoading="isShowLoading"></loading>
          <!--news-head-->
          <div
            class="
              news-list-head
              d-flex
              justify-content-between
              align-items-center
              mb-5
            "
          >
            <div class="news-head-title">
              <h1 class="edc-color-primary fw-bold fs-2 edc-section-title">
                {{ $t("title.news") }}
              </h1>
            </div>
            <div class="news-head-choose-display d-none d-sm-block">
              <div class="d-flex justify-content-between align-items-center">
                <span class="fs-6 ecd-text-muted">
                  {{ $t("title.display") }}
                </span>
                <button
                  type="button"
                  @click.prevent="displayBox()"
                  :class="{
                    active: gridType === 'box',
                  }"
                  aria-label="Display Box"
                  class="btn-display grid mx-1 p-1 rounded-10"
                >
                  <i class="fas fa-th-large fs-2"></i>
                </button>
                <button
                  type="button"
                  @click.prevent="displayRow()"
                  :class="{
                    active: gridType === 'row',
                  }"
                  aria-label="Display Row"
                  class="btn-display list mx-1 p-1 rounded-10"
                >
                  <i class="fas fa-bars fs-2"></i>
                </button>
              </div>
            </div>
          </div>
          <!--//news-head-->
          <div class="row" v-if="!isEmptyData">
            <news
              v-for="(item, index) in posts"
              :key="index"
              :post="item"
            ></news>
          </div>
          <div v-else>
            <div class="alert alert-danger">
              {{ $t("message.not_post_found") }}
            </div>
          </div>
        </div>
        <!--loadmore-->
        <div
          v-if="isShowLoadMoreButton"
          class="my-donations-loadmore my-3 py-3 text-center fs-5 fw-bold"
        >
          <a href="#" @click.prevent="loadMore()" class="edc-color-primary"
            ><span class="ms-4 d-inline">{{ $t("btn.load_more") }}</span>
            <i class="fas fa-chevron-down"></i
          ></a>
        </div>
        <!--//loadmore--->
      </div>
    </div>
  </div>
</template>

<script>
import News from "../../components/News.vue";
import loading from "../../components/Loading.vue";
import { mapGetters, mapActions } from "vuex";
import store from "../../store/index";
export default {
  data() {
    return {
      limit: 9,
      offset: 0,
      gridType: "box",
    };
  },
  components: { News, loading },
  name: "NewsPage",
  computed: {
    ...mapGetters("news", [
      "posts",
      "isShowLoadMoreButton",
      "isEmptyData",
      "isShowLoading",
    ]),
  },
  methods: {
    ...mapActions("news", ["getPosts", "resetPosts"]),
    loadMore() {
      this.offset++;
      this.getPosts({
        limit: this.limit,
        offset: this.offset,
        filter: this.$route.params.filter,
        slug: this.$route.params.slug,
      });
    },
    loadPosts() {
      this.resetPosts([]);
      this.getPosts({
        limit: this.limit,
        offset: this.offset,
        filter: this.$route.params.filter,
        slug: this.$route.params.slug,
      });
    },
    displayRow() {
      this.$root.$emit(
        "updateNewsView",
        "col-lg-12 col-md-12",
        "display-list  d-flex gap-4"
      );
      localStorage.setItem("news-display", "row");
      this.gridType = "row";
    },
    displayBox() {
      this.$root.$emit("updateNewsView", "col-lg-4 col-md-6", "");
      localStorage.setItem("news-display", "box");
      this.gridType = "box";
    },
  },
  created() {
    this.loadPosts();
    this.gridType = localStorage.getItem("news-display");
  },
  updated() {
    if (this.posts.length < this.limit) {
      store.commit("news/SET_IS_LOAD_MORE", false);
    }
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.news") + " | %s",
      meta: [{ property: "og:title", content: this.$t("title.news") }],
    };
  },
  watch: {
    "$route.fullPath": {
      deep: true,
      handler(value) {
        this.loadPosts();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>