<template>
  <div>
    <div class="page-content py-5 pt-0">
      <!--slider-->
      <div class="library-container overflow-hidden">
        <div class="library-slider-container-bg">
          <div class="container text-white">
            <div
              id="quranProjectCarousel"
              class="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item">
                  <div class="row py-0">
                    <div class="col-12 col-lg-10 row m-auto">
                      <div class="col-12 col-lg-5 pt-5 text-center my-auto">
                        <img
                          data-animation="animated bounceInDown"
                          src="@/assets/images/library-slider-image.png"
                          class="img-fluid h-100"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div class="col-12 col-lg-6 my-auto">
                        <h2
                          class="p-0 m-0 mb-2 edc-color-blue"
                          data-animation="animated bounceInUp"
                        >
                          المكتبة المركزية
                        </h2>
                        <div
                          class="info lh-lg text-justify edc-color-black"
                          data-animation="animated zoomInLeft"
                        >
                          جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                          وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                          أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم
                          وترجمات القرآن الكريم
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--//carousel-item1-content-->
                </div>
                <!--//carousel-item1-->
                <div class="carousel-item active">
                  <div class="row py-0">
                    <div class="col-12 col-lg-10 row m-auto">
                      <div class="col-12 col-lg-6 my-auto">
                        <h2
                          class="p-0 m-0 mb-2 edc-color-secondary"
                          data-animation="animated bounceInRight"
                        >
                          المكتبة المركزية
                        </h2>
                        <div
                          class="info lh-lg text-justify edc-color-black"
                          data-animation="animated zoomInRight"
                        >
                          جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                          وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                          أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم
                          وترجمات القرآن الكريم
                        </div>
                      </div>
                      <div class="col-12 col-lg-5 pt-5 text-center my-auto">
                        <img
                          data-animation="animated bounceInLeft"
                          src="@/assets/images/library-slider-image.png"
                          class="img-fluid h-100"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                  <!--//carousel-item2-content-->
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#quranProjectCarousel"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#quranProjectCarousel"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--// slider-->
      <!--Achievements-->
      <div class="library-achievements-container overflow-hidden py-5">
        <div class="container">
          <h4 class="edc-color-primary fw-bold fs-2 edc-section-title">
            إنجازات المكتبة
          </h4>
          <div class="library-achievments-box overflow-hidden row mt-2">
            <!-- boxOne-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  library-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="library-achievments-item-thumble">
                  <img
                    src="@/assets/images/projectimage-large.png"
                    class="
                      library-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="library-achievments-item-title">
                  <a href="#" class="h4 py-3 d-block color-dark"
                    >إدارة الإفتاء والبحوث الشريعة تكرم لجنة الدعوة
                    الإلكترونية.</a
                  >
                </div>
                <div class="library-achievments-item-icons p-2 row">
                  <div
                    class="
                      flex-column flex-lg-row
                      fs-5
                      d-flex
                      justify-content-between
                      p-3
                      gap-1
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1 mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-eye text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-cloud-download-alt text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-book text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    library-achievments-item-details
                    text-muted text-justify
                  "
                >
                  جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري وبرنامج
                  بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م) أفضل موقع دعوي
                  لخدمة القرآن الكريم. إذاعات علوم وترجمات القرآن الكريم
                </div>
              </div>
            </div>
            <!--// boxOne-->
            <!-- boxTow-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  library-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="library-achievments-item-thumble">
                  <img
                    src="@/assets/images/projectimage-large.png"
                    class="
                      library-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="library-achievments-item-title">
                  <a href="#" class="h4 py-3 d-block color-dark"
                    >إدارة الإفتاء والبحوث الشريعة تكرم لجنة الدعوة
                    الإلكترونية.</a
                  >
                </div>
                <div class="library-achievments-item-icons p-2 row">
                  <div
                    class="
                      flex-column flex-lg-row
                      fs-5
                      d-flex
                      justify-content-between
                      p-3
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-eye text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-cloud-download-alt text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-book text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    library-achievments-item-details
                    text-muted text-justify
                  "
                >
                  جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري وبرنامج
                  بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م) أفضل موقع دعوي
                  لخدمة القرآن الكريم. إذاعات علوم وترجمات القرآن الكريم
                </div>
              </div>
            </div>
            <!--// boxTow-->
            <!-- boxThree-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  library-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="library-achievments-item-thumble">
                  <img
                    src="@/assets/images/projectimage-large.png"
                    class="
                      library-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="library-achievments-item-title">
                  <a href="#" class="h4 py-3 d-block color-dark"
                    >إدارة الإفتاء والبحوث الشريعة تكرم لجنة الدعوة
                    الإلكترونية.</a
                  >
                </div>
                <div class="library-achievments-item-icons p-2 row">
                  <div
                    class="
                      flex-column flex-lg-row
                      fs-5
                      d-flex
                      justify-content-between
                      p-3
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-eye text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-cloud-download-alt text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        edc-border-primary
                        rounded-20
                        py-1
                        px-3
                        bg-white
                        mb-1
                      "
                    >
                      <div
                        class="bg-white px-2 py-1 edc-border-primary rounded-10"
                      >
                        <i class="fas fa-book text-black"></i>
                      </div>
                      <div class="edc-color-secondary ms-2">
                        <span class="d-block">
                          <count-up :endVal="100"></count-up>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    library-achievments-item-details
                    text-muted text-justify
                  "
                >
                  جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري وبرنامج
                  بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م) أفضل موقع دعوي
                  لخدمة القرآن الكريم. إذاعات علوم وترجمات القرآن الكريم
                </div>
              </div>
            </div>
            <!--// boxThree-->
          </div>
        </div>
      </div>
      <!--//Achievements-->
      <!--about-->
      <div class="library-about-container overflow-hidden">
        <div class="library-about-box pt-5">
          <div class="overflow-hidden">
            <div class="row">
              <div
                class="col-12 col-md-12 col-lg-6 pt-3 text-center edc-bg-blue"
              >
                <img
                  src="@/assets/images/man2.png"
                  class="h-100"
                  data-aos="fade-left"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="col-12 col-md-12 col-lg-6 bg-white">
                <div class="p-5">
                  <h1 class="h4 edc-color-primary" data-aos="zoom-in">
                    عن المشروع
                  </h1>
                  <div class="info mt-4 mx-lg-5 text-muted" data-aos="fade-in">
                    جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري وبرنامج
                    بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م) أفضل موقع دعوي
                    لخدمة القرآن الكريم. إذاعات علوم وترجمات القرآن الكريم
                  </div>
                  <ul
                    class="liststyle-none mt-md-5 mx-md-5 text-muted"
                    data-aos="fade-left"
                  >
                    <li class="mt-3 d-flex" data-aos="fade-right">
                      <i class="fa fa-check-double"></i>
                      <span class="ms-2 d-inline"
                        >جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                        وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                        أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم وترجمات
                        القرآن الكريم</span
                      >
                    </li>
                    <li class="mt-3 d-flex" data-aos="fade-right">
                      <i class="fa fa-check-double"></i>
                      <span class="ms-2 d-inline">
                        جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                        وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                        أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم وترجمات
                        القرآن الكريم
                      </span>
                    </li>
                    <li class="mt-3 d-flex" data-aos="fade-right">
                      <i class="fa fa-check-double"></i>
                      <span class="ms-2 d-inline"
                        >جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                        وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                        أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم وترجمات
                        القرآن الكريم</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--//about-->
      <!--Goals-->
      <div class="library-goals-container overflow-hidden py-5">
        <div class="container">
          <div class="library-goals-box row">
            <h4
              class="
                library-goals-box-right-title
                p-0
                edc-section-title
                fw-bold
                edc-color-primary
                fs-2
              "
            >
              أهداف المشروع
            </h4>
            <div class="col-12">
              <div class="container p-0 mt-3">
                <div class="row p-0 overflow-hidden">
                  <!--box-->
                  <div
                    class="col-12 col-lg-4 mb-3 col-md-6 text-center"
                    data-aos="fade-up"
                  >
                    <div class="bg-white rounded-30 border-1 py-4 px-3">
                      <div class="icon m-2">
                        <img src="@/assets/images/book-icon.png" />
                      </div>
                      <div class="text">
                        <h1 class="h5 edc-color-primary mb-3">كتب منوعة</h1>
                        <p class="text-muted text-justify">
                          جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                          وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                          أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم
                          وترجمات القرآن الكريم
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                  <!--box-->
                  <div
                    class="col-12 col-lg-4 mb-3 col-md-6 text-center"
                    data-aos="fade-right"
                  >
                    <div class="bg-white rounded-30 border-1 py-4 px-3">
                      <div class="icon m-2">
                        <img src="@/assets/images/head-icon.png" />
                      </div>
                      <div class="text">
                        <h1 class="h5 edc-color-primary mb-3">
                          زيادة توعية المستمعين
                        </h1>
                        <p class="text-muted text-justify">
                          جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                          وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                          أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم
                          وترجمات القرآن الكريم
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                  <!--box-->
                  <div
                    class="col-12 col-lg-4 mb-3 col-md-6 text-center"
                    data-aos="fade-down"
                  >
                    <div class="bg-white rounded-30 border-1 py-4 px-3">
                      <div class="icon m-2">
                        <img src="@/assets/images/book2-icon.png" />
                      </div>
                      <div class="text">
                        <h1 class="h5 edc-color-primary mb-3">
                          نخبة من الكتاب
                        </h1>
                        <p class="text-muted text-justify">
                          جائز موقع ومبادرة نون بالتعاون مع الشيخ فهد الكندري
                          وبرنامج بالقرآن اهتديت (أفضل المشاريع الدعوية 2016م)
                          أفضل موقع دعوي لخدمة القرآن الكريم. إذاعات علوم
                          وترجمات القرآن الكريم
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--//Goals-->
      <!--status-->
      <div class="library-status-container overflow-hidden py-5">
        <div class="container">
          <h4
            class="
              library-status-title
              edc-color-primary
              fw-bold
              fs-2
              mb-3
              edc-section-title
            "
          >
            المكتبة إحصائيات
          </h4>
          <!--box one-->
          <div class="library-status-blocks overflow-hidden row">
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="flip-left"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <img
                  src="@/assets/images/hand-donate-icon.png"
                  class="mb-3"
                  alt=""
                  srcset=""
                />
                <h4 class="mb-3 fs-3">اجمالي الزيارات</h4>
                <div class="m-countup library-status-block-count fs-2">
                  <count-up :endVal="parseInt(status.library.visits)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box one-->
            <!--box tow-->
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <img
                  src="@/assets/images/benifite.png"
                  class="mb-3"
                  alt=""
                  srcset=""
                />
                <h4 class="mb-3 fs-3">اجمالي التحميلات</h4>
                <div class="library-status-block-count fs-2">
                  <count-up :endVal="parseInt(status.library.downloads)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box tow-->
            <!--box three-->
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <img
                  src="@/assets/images/respect.png"
                  alt=""
                  class="mb-3"
                  srcset=""
                />
                <h4 class="mb-3 fs-3">عدد الكتب</h4>
                <div class="library-status-block-count fs-2">
                  <count-up :endVal="parseInt(status.library.books_total)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box three-->
          </div>
        </div>
      </div>
      <!--//status-->
      <testimonial
        :head_title="`title.testimonial_library`"
        section="library"
      ></testimonial>

      <!--donate-->
      <div class="library-donate-container overflow-hidden bg-white py-5">
        <div
          class="container d-flex justify-content-between align-items-center"
        >
          <div class="library-donate-title">
            <h4 class="py-2 fs-2">{{ $t("label.donate_to_project") }}</h4>
          </div>
          <div class="library-donate-button" data-aos="zoom-in">
            <a
              href="#"
              class="btn edc-btn-primary text-white p-2 px-5 rounded-20 fs-md-4"
            >
              {{ $t("btn.donate_now") }}
              <i class="fas fa-hand-holding-usd"></i>
            </a>
          </div>
        </div>
      </div>
      <!--//donate-->
    </div>
  </div>
</template>

<script>
import Testimonial from "../../components/Testimonial.vue";
import ogImage from "@/assets/images/logo.svg";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { Testimonial },
  name: "MuslimLibrary",
  computed: {
    ...mapGetters("project_status", ["status"]),
  },
  methods: {
    ...mapActions("project_status", ["getStatusValue"]),
  },
  created() {
    this.getStatusValue();
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.muslim_library") + " | %s",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("title.muslim_library"),
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:url", property: "og:url", content: window.location.href },
        {
          vmid: "og:image",
          property: "og:image",
          content: window.location.origin + ogImage || "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>