<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <div v-if="lists.length <= 0" class="alert-info p-2 text-center">
          {{ $t("message.not_notify_found") }}
        </div>
        <div v-else>
          <notification
            v-for="(item, index) in lists"
            :key="index"
            :item="item"
          ></notification>
        </div>
        <div>
          <loading-page :isShow="isLoading"></loading-page>
          <div
            v-if="isLoadMore"
            class="my-donations-loadmore my-3 py-3 text-center fs-5 fw-bold"
          >
            <a @click.prevent="loadMore()" href="#" class="edc-color-primary"
              ><span class="ms-4 d-inline">عرض المزيد</span
              ><i class="fas fa-chevron-down"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingPage from "../components/LoadingPage.vue";
import Notification from "../components/Notification.vue";
export default {
  components: { Notification, LoadingPage },
  name: "notficatios-page",
  data() {
    return {
      offset: 0,
      limit: 10,
    };
  },
  computed: {
    ...mapGetters("notify", ["lists", "isLoadMore", "isLoading"]),
  },
  methods: {
    ...mapActions("notify", ["getPaginateList"]),
    loadMore() {
      this.offset++;
      this.getPaginateList({ limit: this.limit, offset: this.offset });
    },
  },
  created() {
    this.getPaginateList({ limit: this.limit, offset: this.offset });
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.show_notifications") + " | %s",
      meta: [
        { property: "og:title", content: this.$t("title.show_notifications") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>