<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <div class="edc-reports-box">
          <loading :isShowLoading="isShowLoading"></loading>
          <!--reports-head-->
          <div
            class="
              reports-list-head
              d-flex
              justify-content-between
              align-items-center
              mb-5
            "
          >
            <div class="reports-head-title">
              <h1 class="edc-color-primary fw-bold fs-2 edc-section-title">
                {{ $t("title.reports") }}
              </h1>
            </div>
            <div class="reports-head-choose-display d-none d-sm-block">
              <div class="d-flex justify-content-between align-items-center">
                <span class="fs-6 text-muted">
                  {{ $t("") }}
                </span>
                <button
                  type="button"
                  @click.prevent="displayBox()"
                  :class="{
                    active: gridType === 'box',
                  }"
                  aria-label="Display Box"
                  class="btn-display grid mx-1 p-1 rounded-10"
                >
                  <i class="fas fa-th-large fs-2"></i>
                </button>
                <button
                  type="button"
                  @click.prevent="displayRow()"
                  :class="{
                    active: gridType === 'row',
                  }"
                  aria-label="Display Row"
                  class="btn-display list mx-1 p-1 rounded-10"
                >
                  <i class="fas fa-bars fs-2"></i>
                </button>
              </div>
            </div>
          </div>
          <!--//reports-head-->
          <div class="row" v-if="!isEmptyData">
            <reports
              v-for="(item, index) in reports"
              :key="index"
              :report="item"
            ></reports>
          </div>
          <div v-else>
            <div class="alert alert-danger">
              {{ $t("message.not_report_found") }}
            </div>
          </div>
        </div>
        <!--loadmore-->
        <div
          v-if="isShowLoadMoreButton"
          class="my-donations-loadmore my-3 py-3 text-center fs-5 fw-bold"
        >
          <a href="#" @click.prevent="loadMore()" class="edc-color-primary"
            ><span class="ms-4 d-inline">{{ $t("btn.load_more") }}</span>
            <i class="fas fa-chevron-down"></i
          ></a>
        </div>
        <!--//loadmore--->
      </div>
    </div>
  </div>
</template>

<script>
import Reports from "../../components/Reports.vue";
import loading from "../../components/Loading.vue";
import { mapActions, mapGetters } from "vuex";
import store from "../../store";

export default {
  name: "ReportsPage",
  data() {
    return {
      limit: 6,
      offset: 1,
      gridType: "box",
    };
  },
  components: { Reports, loading },
  computed: {
    ...mapGetters("reports", [
      "reports",
      "isShowLoadMoreButton",
      "isEmptyData",
      "isShowLoading",
    ]),
  },
  methods: {
    ...mapActions("reports", ["getReports", "resetReports"]),
    loadMore() {
      this.offset++;
      this.getReports({
        limit: this.limit,
        offset: this.offset,
      });
    },
    loadReports() {
      this.resetReports([]);
      this.getReports({
        limit: this.limit,
        offset: this.offset,
      });
    },
    displayRow() {
      this.$root.$emit(
        "updateReportsView",
        "col-lg-12 col-md-12",
        "display-list  d-flex gap-4"
      );
      localStorage.setItem("reports-display", "row");
      this.gridType = "row";
    },
    displayBox() {
      this.$root.$emit("updateReportsView", "col-lg-4 col-md-6", "");
      localStorage.setItem("reports-display", "box");
      this.gridType = "box";
    },
  },
  created() {
    this.loadReports();
    this.gridType = localStorage.getItem("reports-display");
  },
  updated() {
    if (this.reports.length < this.limit) {
      store.commit("reports/SET_IS_LOAD_MORE", false);
    }
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.reports") + " | %s",
      meta: [{ property: "og:title", content: this.$t("title.reports") }],
    };
  },
  watch: {
    "$route.fullPath": {
      deep: true,
      handler(value) {
        this.loadReports();
      },
    },
  },
};
</script>

<style scoped>
</style>