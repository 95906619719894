<template>
  <div class="page-content py-5">
    <div class="container">
      <div
        v-if="insidePost"
        class="
          edc-content-round
          post
          login-content
          rounded-50
          col-lg-9 col-sm-12
          m-auto
          p-3
        "
      >
        <h1 class="post-title h4 fw-bold py-2 py-lg-3">
          {{ insidePost.post_title }}
        </h1>
        <div class="post-image">
          <img
            class="img-fluid rounded-50 w-100"
            :src="insidePost.post_image.large"
            alt=""
            srcset=""
          />
        </div>
        <div
          class="post-details d-flex py-3 border-1 border-bottom edc-color-gray"
        >
          <span class="d-flex align-items-center me-3">
            <i class="far fa-comment-alt fs-3 ms-2"></i>
            <span
              >{{ insidePost.comment_count }} {{ $t("label.comment") }}</span
            >
          </span>
          <span class="d-flex align-items-center me-3">
            <i class="far fa-clock fs-3 ms-2"></i>
            <span>{{ insidePost.post_date }}</span>
          </span>

          <router-link
            :to="{ path: '/news/category/' + category.slug }"
            v-for="category in post.categories"
            :key="category.term_id"
            class="
              tags
              mx-1
              fs-7
              p-2
              rounded-pill
              text-white
              btn
              edc-btn-primary
            "
          >
            <i class="fas fa-check-circle me-1"></i>
            {{ category.name }}
          </router-link>
        </div>
        <div
          class="post-content mt-3 lh-lg fw-bold fs-5 text-justify"
          v-html="insidePost.post_content"
        ></div>
        <div class="post-tags-box mt-2">
          <router-link
            :to="{ path: '/news/tag/' + tag.slug }"
            v-for="tag in post.tags"
            :key="tag.term_id"
            class="
              tags
              mx-1
              fs-7
              p-2
              rounded-pill
              btn btn btn-outline-secondary
            "
          >
            <i class="fas fa-tag me-1"></i>
            {{ tag.name }}
          </router-link>
        </div>
        <div class="post-share-box">
          <div
            class="
              post-share
              border
              rounded-pill
              p-4
              border-2
              mx-auto
              my-3
              col-8
              d-flex
              justify-content-between
            "
          >
            <div class="post-share-title fs-5 edc-color-red">
              <i class="fas fa-share"></i>
              {{ $t("title.share_post") }}
            </div>
            <div class="post-scoial-link">
              <a href="#" class="me-1"><i class="fab fa-facebook-f"></i></a>
              <a href="#" class="me-1"><i class="fab fa-facebook-f"></i></a>
              <a href="#" class="me-1"><i class="fab fa-facebook-f"></i></a>
              <a href="#" class="me-1"><i class="fab fa-facebook-f"></i></a>
              <a href="#" class="me-1"><i class="fab fa-facebook-f"></i></a>
            </div>
          </div>
        </div>
        <div class="post-comment-form">
          <h1 class="h4 edc-color-primary mb-3">
            {{ $t("title.add_comment_title") }}
          </h1>
          <comment-form :post_id="insidePost.id"></comment-form>
        </div>
        <div class="post-comment-box">
          <h1 class="h4 edc-color-primary mb-2">
            {{ $t("title.view_comments") }}
          </h1>
          <comments :post_id="insidePost.id"></comments>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import stroe from "../../store/index";
import Comments from "../../components/Comments.vue";
import CommentForm from "../../components/CommentForm.vue";
//TODO laod more in comment
export default {
  components: { Comments, CommentForm },
  name: "ShowNewsPage",
  data() {
    return {
      insidePost: {
        post_image: {},
      },
    };
  },
  computed: {
    ...mapGetters("news", ["post"]),
  },
  methods: {
    ...mapActions("news", ["getPost"]),
  },
  created() {
    this.getPost({ slug: this.$route.params.slug, type: "post" })
      .then((response) => {
        if (response.status === 200) {
          stroe.commit("news/SET_POST", response.data);
        } else if (response.status === 400) {
          stroe.commit("news/SET_IS_LOAD_MORE", false);
        }
      })
      .catch((error) => {});
  },
  updated() {
    this.insidePost = this.post.post;
  },
  metaInfo() {
    return {
      titleTemplate: this.insidePost.post_title + " | %s",
      meta: [
        { property: "og:title", content: this.insidePost.post_title },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>